import React, {useState} from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import collage from './design/img/collage.jpg';
import img1 from './design/img/img11_web.jpg';
import img2 from './design/img/img22_web.jpg';
import img3 from './design/img/img33_web.jpg';

function PastEvents(){
    return(
        <>
            <Card style={{border:0}}>
                <Card.Body>
                    <Card.Title><p></p></Card.Title>
                    <Card.Img variant="bottom" src={collage} />
                    <Card.Text>
                        <p>30. September - 6. October 2023 | COINCOIN, Ernst-Nobs-Platz 1, 8004-Zürich</p>
                        <p>The exhibition highlights Iranian female artists who, despite facing uncertainty and constraints, are pioneering innovative and unconventional approaches to exploring contemporary issues.
                        </p>
                        <p className="text-bf">Artists</p>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>Bahare Babaei</ListGroup.Item>
                            <ListGroup.Item>Elham Issari</ListGroup.Item>
                            <ListGroup.Item>Marzieh Ghasempour</ListGroup.Item> 
                            <ListGroup.Item>Faezeh Baharlou</ListGroup.Item> 
                            <ListGroup.Item>Sepideh Solati</ListGroup.Item> 
                        </ListGroup>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Container>
                <Row>
                    <Col xs={4} md={4}>
                        <Image src={img1} fluid/>
                    </Col>
                    <Col xs={4} md={4}>
                        <Image src={img2} fluid />
                    </Col>
                    <Col xs={4} md={4}>
                        <Image src={img3} fluid />
                    </Col>
                </Row>
            </Container>
        </>
        )
}

export default PastEvents
