import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from './design/img/logo_small_1.png';
/* Logo: https://react-bootstrap.netlify.app/docs/components/navbar/ */
function Navigation2() {
  return (
      <>
          {
              <Navbar collapseOnSelect key={"lg"} expand={"lg"} className="bg-transparent mb-3">
                  <Container fluid>
                      <Navbar.Brand as={Link} to={"/"}><img src={logo} width="45" className="d-inline-block align-center"/> JOINTARTSPACE</Navbar.Brand><Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
                      <Navbar.Offcanvas
                          id={`offcanvasNavbar-expand-${"lg"}`}
                          aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
                          placement="end"
                      >
                          <Offcanvas.Header closeButton>
                              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
                                  JOINTARTSPACE
                              </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                              <Nav className="justify-content-end flex-grow-1 pe-3">
                                  <Nav.Link eventKey="1" as={Link} to={"/"}>Home</Nav.Link>
                                  <Nav.Link eventKey="2" as={Link} to={"/about"}>About</Nav.Link>
                                  <Nav.Link eventKey="3" as={Link} to={"/artworks/"}>Artists</Nav.Link>
                                  <Nav.Link eventKey="4" as={Link} to={"/pastevents/"}>Past Events</Nav.Link>
                              </Nav>
                          </Offcanvas.Body>
                      </Navbar.Offcanvas>
                  </Container>
              </Navbar>
          }
      </>
  );
}

export default Navigation2;
