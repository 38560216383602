import poster from '../design/callme_final.png';
import Card from 'react-bootstrap/Card';

const Event2=()=>{
    return(
        <>
            <Card style={{border:0}}>
                <Card.Body>
                    <Card.Title><p>This year's exhibition took place between the 6.-8. December 2024 at the VN-Residency, Lessingstrasse 15, 8002-Zürich.</p>
                        <p>The catalog is available on <a href="mailto:office@jointart.space" className="text-reset">request</a>.</p>
                    </Card.Title>
                    <Card.Text>
                    </Card.Text>
                </Card.Body>
                <Card.Img variant="bottom" src={poster} />
            </Card>
        </>
        )
}

export default Event2
